<template lang="html">
  <section class="error_403">
    <div class="container-scroller">
      <div class="container-fluid page-body-wrapper full-page-wrapper">
        <div
          class="
            content-wrapper
            d-flex
            align-items-center
            text-center
            error-page
          "
        >
          <div class="row flex-grow">
            <div class="col-lg-8 mx-auto">
              <div class="row align-items-center d-flex flex-row">
                <div class="error-page-divider text-lg-left pl-lg-4">
                  <h3 class="font-weight-light">
                    很抱歉，您目前未有訪問權限
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- content-wrapper ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script lang="js">
export default {
  name: 'error403'
}
</script>

<style scoped lang="scss">
</style>
